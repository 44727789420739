<template>
    <div>
        <v-chart v-if="showIndex===1" :option="option1" ref="barchats1" class="chart_height"></v-chart>
        <v-chart v-else :option="option2" ref="barchats2" class="chart_height"></v-chart>
    </div>
</template>

<script>
import Echarts,{THEME_KEY} from 'vue-echarts'

export default{
    data:function(){
        return{
            dom1:null,
            dom2:null,
            option1:{
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01]
                },
                yAxis: {
                    type: 'category',
                    data: ['巡更机', 'AI相机', '车牌相机']
                },
                series: [
                    {
                    name: '在线',
                    type: 'bar',
                    data: [60, 45, 50]
                    },
                    {
                    name: '离线',
                    type: 'bar',
                    data: [1, 1, 2]
                    }
                ]
            },
            option2:{
                title:{
                    text:this.hg===0?'':'车流量分析',
                    subtext:'',
                    left:'center'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                        data: ['消1','消2','消3','消4']
                },
                yAxis: {},
                series: [
                    {
                        name: '入场',
                        label: {
                            show: true,
                            position: 'inside'
                        },
                        type: 'bar',
                        data: [50.05,32.15,42.65,19.22]
                    }
                ]
            }
        }
    },
    components:{
        'v-chart':Echarts
    },
    props:{
        datalist:Array,
        showIndex:Number,
        isrefresh:Boolean,
        hg:Number
    },
    created(){
        
    },
    mounted(){
        this.dom1=this.$refs.barchats1
        this.dom2=this.$refs.barchats2
    },
    methods:{
        // setConfig(){
        //      if(this.showIndex===1){
        //         this.dom1.setOption({
        //             series:[{
        //                 data:this.datalist[0]
        //             }]
        //         });
        //     }
        //     else{
        //         this.dom2.setOption({
        //             series:[{
        //                 data:this.datalist[0]
        //             },{
        //                 data:this.datalist[1]
        //             }]
        //         })
        //     }
            
        // }
        
    },
    watch:{
        // isrefresh(){
        //     this.setConfig()
        // }
    }
}
</script>

<style>
    .chart_height{
        height:calc(50vh - 100px)
    }
</style>