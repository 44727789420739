<template>
    <div>
        <dv-digital-flop :config="config"/>
    </div>
</template>

<script>
export default{
    data:function(){
        return{
            config:{
                number:[this.data],
                toFixed:this.Fixed,
                content: '{nt}',
                style:{fill:this.fontColor===1?'#0DF9AA':(this.fontColor===2?'#FE7153':(this.fontColor===3?"#F9C134":'rgb(0, 253, 250)'))} 
            },
            refresh:this.isRefresh
        }
    },
    props:{
        data:{
            type:Number,
            default () {
                return 0
            } 
        },
        Fixed:{
            type:Number,
            default () {
                return 0
            } 
        },
        fontColor:{
            type:Number,
        },
        isRefresh:{
            type:Boolean,
            default(){
                return false
            }
        }
    },
    created(){

    },
    methods:{
        SetConifg(){
            this.config={
                number:[this.data],
                toFixed:this.Fixed,
                content: '{nt}',
                style: {fill:this.fontColor===1?'#0DF9AA':(this.fontColor===2?'#FE7153':(this.fontColor===3?"#F9C134":'rgb(0, 253, 250)'))} 
            }
        }
    },
    watch:{
        isRefresh(newValue,oldValue){
            this.SetConifg()
        }
    }
}
</script>

<style>

</style>